import React, { Fragment } from 'react'
import { Global } from '@emotion/react'
import { globalStyles } from '../components/layout.styles'
import SEO from "../components/Seo"
import NavBar from '../components/NavBar3'
import Footer from '../components/Footer'
import Img1 from '../images/new/blog/oficina-inclusiva.png'
import Img2 from '../images/new/blog/retrato-de-belchior.png'

import { 
  section,
  container,
  content,
} from '../components/styles/Base.styles'


const BlogItem = props => (
  <Fragment>
    <Global styles={globalStyles} />
    <SEO title="Kingdom promove oficina de arte inclusiva em homenagem ao bicentenário da independência e aos 62 anos de Brasília | Um reino de aprendizado e conhecimento" />
    <NavBar />
    {/* <div css={HeaderBanner}>
      <img src={Banner} alt='' style={{ width: '100%' }} />
    </div> */}
    <div css={[section, content]} style={{ paddingBottom: 0, marginTop: 112 }}>
      <div css={container}>
        <h1>Kingdom promove oficina de arte inclusiva em homenagem ao bicentenário da independência e aos 62 anos de Brasília</h1>
        <h3 style={{ color: '#000000', marginBottom: 36, maxWidth: '100%' }}>Em parceria com o Sebrae e a Secretaria de Educação, escola recebeu o artista Carlos Bracher e 80 alunos surdos e de baixa visão da Rede Pública do DF</h3>
				<p>Certa vez um homem disse que <span>"a arte existe porque a vida não é o bastante"</span>. Para Ferreira Gullar, poeta e dramaturgo, a vida era tela em branco aberta ante o espírito e a invenção dos homens. Mas e o artista? Para Gullar, o premiadíssimo <span>Carlos Bracher</span> — artista plástico que liderou a Oficina de Arte Inclusiva realizada no último dia 18 de maio na quadra da Kingdom School em Brasília — era puro ímpeto "movido pela necessidade de colher a beleza no mesmo momento em que ela, fustigada, emerge à luz”.</p>
        <div style={{ marginBottom: 36, textAlign: 'center' }}>
					<img src={Img1} alt='' style={{ marginTop: 0, marginBottom: 0 }} />
					<p style={{ fontSize: 14 }}>Aluno com baixa visão participa de Oficina Inclusiva na quadra do Kingdom School</p>
				</div>
				<p>Quando educadores e funcionários da Kingdom começaram a aprontar os detalhes para receber os oitenta estudantes selecionados junto ao Centro de Escola Bilíngue em Taguatinga e o Centro de Ensino Especial de Deficientes Visuais da Asa Sul, ninguém imaginava que as palavras ditas por Ferreira Gullar, há mais de 30 anos, seriam vividas em sua plenitude durante todo o dia. A arte, o artista e os alunos com algum tipo de deficiência visual ou auditiva mergulharam em uma rica experiência entre telas, pincéis e tintas para criar obras que celebram os duzentos anos da Independência do Brasil e os sessenta e dois anos da fundação da Capital do país. Para Alice Simão, diretora da Kingdom School, "é muito valioso sentir como a arte ajuda a dar corpo a conceitos como afeto e acolhimento — que são fundamentais para a Escola — mas que muitas vezes não conseguem ser percebidos pelas pessoas."</p>
				<div style={{ marginBottom: 36, textAlign: 'center' }}>
					<h3 style={{ maxWidth: '100%' }}>"Amar a própria arte é a maior revolução"</h3>
					<img src={Img2} alt='' style={{ marginTop: 0, marginBottom: 0 }} />
					<p style={{ fontSize: 14 }}>Retrato de Belchior pintado por Bracher em Ouro Preto desapareceu junto com cantor, morto em 2017.</p>
				</div>
				<p>Aos 82 anos, Bracher, é o artista brasileiro que, em vida, mais expôs no exterior. O mineiro realizou exposições individuais em galerias e museus mundo afora: em Paris, Roma, Milão, Moscou, Tóquio, Beijing, Londres, Rotterdam, Haia, Madri, Lisboa, Montevidéu, Santiago do Chile, Bogotá e Kingston. Mas a estrada longa não enfraquece o ímpeto que inspirando as pessoas comoveu o poeta e amigo Ferreira Gullar. "Um dos maiores momentos da minha vida é estar aqui" — comenta Bracher sobre a emoção que sentiu ao misturar tintas e sonhos com os jovens aprendizes da Oficina. Para todos os que viveram a experiência de compartilhar conhecimento e arte durante a Oficina, a vida pode não ser o bastante, mas arte nunca é demais.</p>
				{/* <div css={columns}>
          <div css={column}>
            <div css={socialShare}>
              <h4>21 Nov 2021</h4>
              <div css={SocialTags}>
                <div css={tagsBlog}>
                  <div css={tagItem}>Lorem Ipsum</div>
                  <div css={tagItem}>Lorem Ipsum</div>
                  <div css={tagItem}>Lorem Ipsum</div>
                </div>
                <div css={socialIcons}>
                  <img src={Share} style={{ width: 109, marginBottom: 0, marginTop: 0 }} />
                  <a href='https://www.facebook.com/sharer/sharer.php?u=https%3A//mykingdomschool.netlify.app/blog-item'><img src={Facebook} /></a>
                  <a href='https://twitter.com/intent/tweet?text=https%3A//mykingdomschool.netlify.app/blog-item'><img src={Twitter} /></a>
                  <a href='https://www.linkedin.com/shareArticle?mini=true&url=https%3A//twitter.com/intent/tweet?text=https%253A//mykingdomschool.netlify.app/blog-item&title=&summary=&source='><img src={Linkedin} /></a>
                </div>
              </div>
            </div>
            <div style={{ paddingBottom: 112 }} />
          </div>
        </div> */}
				<div style={{ paddingBottom: 112 }} />
      </div>
    </div>
    <Footer />
  </Fragment>
)

export default BlogItem
